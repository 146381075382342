import * as React from "react"
import {Parallax, ParallaxLayer, IParallax} from '@react-spring/parallax'
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import Seo from "@lekoarts/gatsby-theme-cara/src/components/seo"
import {useRef} from "react";
import "./index.scss"
import logo from '../images/logo.png'
import people from '../images/people.png'
import measure from '../images/measure.png'
import leaves from '../images/leaves.png'
import hands from '../images/hands.png'
import walnut from '../images/walnut2.png'

const Home = (props) => {
    const pRef = useRef();
    const colors = {
        white: '#E2E8F0',
        grey: '#3B4444',
        ocean: '#5DC4C4',
        lime: '#D5D402',
        lightgrey: '#E6F4E7',
        green: '#78CC77'
    }
    const scroll = (to) => {
        console.log(to);
        if (pRef.current) {
            pRef.current.scrollTo(to)
        }
    }
    return (
        <Layout>
            <Seo title="LOOPCARE Homepage"/>
            <Parallax pages={7} style={{top: '0', left: '0'}} ref={pRef}>
                <ParallaxLayer
                    onClick={() => scroll(1)}
                    offset={0}
                    speed={.6}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: colors.ocean,
                        height: '200vh'
                    }}>
                </ParallaxLayer>
                <ParallaxLayer
                    onClick={() => scroll(2)}
                    offset={1}
                    speed={.6}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: colors.lime,
                        height: '200vh'
                    }}>
                </ParallaxLayer>
                <ParallaxLayer
                    onClick={() => scroll(3)}
                    offset={2}
                    speed={.6}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: colors.lightgrey,
                        height: '200vh'
                    }}>
                </ParallaxLayer>
                <ParallaxLayer
                    onClick={() => scroll(4)}
                    offset={3}
                    speed={.6}
                    style={{
                        justifyContent: 'start',
                        background: colors.green,
                        height: '200vh'
                    }}>
                    <div className="cover"
                         style={{backgroundImage: `url(${hands})`, backgroundPosition: 'center'}}></div>

                </ParallaxLayer>
                <ParallaxLayer
                    onClick={() => scroll(5)}
                    offset={4}
                    speed={.6}
                    style={{
                        justifyContent: 'start',
                        background: colors.lightgrey,
                        height: '300vh'
                    }}>
                </ParallaxLayer>
                <ParallaxLayer
                    onClick={() => scroll(6)}
                    offset={5}
                    speed={.6}
                    style={{
                        justifyContent: 'start',
                        background: colors.lightgrey,
                        height: '200vh'
                    }}>
                </ParallaxLayer>
                <ParallaxLayer
                    onClick={() => scroll(7)}
                    offset={6}
                    speed={.6}
                    style={{
                        justifyContent: 'start',
                        background: colors.ocean,
                        height: '200vh'
                    }}>
                    <div className="contain" style={{
                        backgroundImage: `url(${walnut})`,
                        backgroundColor: '#fff',
                        backgroundPosition: 'center'
                    }}></div>
                </ParallaxLayer>
                <ParallaxLayer
                    onClick={() => scroll(1)}
                    offset={0}
                    speed={.2}
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="vertical-flex" style={{height: '100%', width: '100%'}}>
                        <div className="centered" style={{flex: 1, marginTop: '120px'}}>
                            <div className="flex align-items responsive">
                                <div className="image"><img className="logo" src={logo}/></div>
                                <div className="title">
                                    <h2 style={{fontWeight: 400}}>sustainable</h2>
                                    <h2 style={{fontWeight: 200, color: colors.grey}}>weightloss <span
                                        style={{fontWeight: 400, color: colors.white}}>for</span></h2>
                                    <h2><span style={{fontWeight: 800, color: colors.grey}}>overweight</span><span
                                        style={{fontWeight: 400, color: colors.grey}}> people</span></h2>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{flex: .1, fontWeight: 200, color: colors.grey, fontSize: '1.3rem', padding: '30px'}}
                            className="subtitle">lasting behavioral change<br/>
                            powered by machine learning
                        </div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    onClick={() => scroll(2)}
                    offset={1}
                    speed={.2}
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="vertical-flex" style={{
                        height: '100%',
                        width: '100%',
                        backgroundImage: measure,
                        backgroundRepeat: 'none',
                        backgroundPosition: 'bottom center'
                    }}>
                        <div className="centered" style={{flex: 1}}>
                            <div className="flex align-items responsive">
                                <div className="image"><img style={{paddingRight: '60px', width: '400px'}}
                                                            src={people}/></div>
                                <div className="title" style={{fontWeight: 600}}>
                                    <h2 style={{fontWeight: 600, fontSize: '4rem'}}>1 in 5 adults</h2>
                                    <h2 style={{fontWeight: 600, fontSize: '4rem'}}>suffer from</h2>
                                    <h2><span style={{fontWeight: 600, fontSize: '4rem'}}>overweight</span></h2>
                                    <div style={{
                                        flex: .2,
                                        fontWeight: 400,
                                        color: colors.grey,
                                        fontSize: '1.3rem',
                                        padding: '40px 0'
                                    }}>70% OF THESE HAVE A FOOD ADDICTION
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    onClick={() => scroll(3)}
                    offset={2}
                    speed={.2}
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="vertical-flex" style={{
                        height: '100%',
                        width: '100%'
                    }}>
                        <div className="title" style={{fontWeight: 600}}>
                            <h2 style={{
                                fontWeight: 600,
                                fontSize: '4rem',
                                color: colors.grey,
                                padding: '120px 50px'
                            }}>We need to
                                act</h2>
                        </div>
                        <div className="centered" style={{flex: 1}}>
                            <div className="flex align-items responsive">
                                <div className="image"><img
                                    style={{width: '50% !important', maxWidth: 'inherit'}}
                                    src={leaves}/></div>

                            </div>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    onClick={() => scroll(4)}
                    offset={3}
                    speed={.2}
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="flex" style={{
                        height: '100%',
                        width: '100%'
                    }}>
                        <div style={{flex: 1.2}}></div>
                        <div className="flex" style={{
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'right',
                            height: '100%'
                        }}>
                            <div className="flex align-items responsive"
                                 style={{padding: '0 0 80px 0', flex: 0.8, width: '80%'}}>
                                <div className="title" style={{fontWeight: 600}}>
                                    <h2 style={{fontWeight: 600, fontSize: '4rem', color: colors.grey}}>digitize a</h2>
                                    <h2 style={{fontWeight: 600, fontSize: '4rem', color: colors.white}}>
                                        scientifically</h2>
                                    <h2 style={{fontWeight: 600, fontSize: '4rem', color: colors.grey}}>proven clinical
                                        treatment</h2>
                                </div>
                            </div>
                            <div
                                style={{
                                    fontWeight: 300,
                                    color: colors.grey,
                                    fontSize: '1.6rem',
                                    textAlign: 'left',
                                    width: '80%'
                                }}
                                className="subtitle"><span style={{
                                color: colors.white,
                            }}>80%</span> still on target 5 years after<br/>
                                ending treatment
                            </div>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    onClick={() => scroll(5)}
                    offset={4}
                    speed={.2}
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="flex" style={{
                        height: '100%',
                        width: '100%'
                    }}>
                        <div style={{flex: 1.2}}></div>
                        <div className="centered" style={{flex: 1}}>
                            <div className="flex align-items responsive" style={{padding: '80px'}}>
                                <div className="title">
                                    <h2 style={{fontWeight: 400, fontSize: '3.8rem', color: colors.grey}}>what we</h2>
                                    <h2 style={{fontWeight: 400, fontSize: '3.8rem', color: colors.grey}}>
                                        <span style={{fontWeight: 700}}>offer</span> customers</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    onClick={() => scroll(6)}
                    offset={5}
                    speed={.2}
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="flex" style={{
                        height: '100%',
                        width: '100%'
                    }}>
                        <div style={{flex: 1.2}}></div>
                        <div className="centered" style={{flex: 1}}>
                            <div className="flex align-items responsive" style={{padding: '80px'}}>
                                <div className="title">
                                    <h2 style={{fontWeight: 400, fontSize: '3.8rem', color: colors.grey}}>What
                                        makes</h2>
                                    <h2 style={{fontWeight: 400, fontSize: '3.8rem', color: colors.grey}}>
                                        <span style={{
                                            fontWeight: 600,
                                            fontSize: '3.8rem',
                                            color: colors.grey
                                        }}>us</span> unique</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    onClick={() => scroll(0)}
                    offset={6}
                    speed={.2}
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="flex" style={{
                        height: '100%',
                        width: '100%'
                    }}>
                        <div style={{flex: 1.2}}></div>
                        <div className="centered" style={{flex: 1}}>
                            <div className="flex align-items responsive" style={{padding: '80px'}}>
                                <div className="title">
                                    <h2 style={{fontWeight: 400, fontSize: '3.8rem', color: colors.grey}}>Losing
                                        weight</h2>
                                    <h2 style={{fontWeight: 400, fontSize: '3.8rem', color: colors.grey}}>
                                        is a <span style={{
                                        fontWeight: 600,
                                        fontSize: '3.8rem',
                                        color: colors.grey
                                    }}>mind</span> game</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </ParallaxLayer>
            </Parallax>
            <div className="logo-wrapper">
                <div className="inline-flex">
                    <div style={{fontWeight: 200, color: colors.grey}}>LOOP</div>
                    <div style={{fontWeight: 700, color: colors.grey}}>CARE</div>
                </div>
            </div>

        </Layout>
    )
};

export default Home;
